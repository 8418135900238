var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"v3.2.0-hotfix.0"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import { BaseTransportOptions, Transport, TransportRequestExecutor } from '@sentry/types/types/transport';

function makeFetchTransport(
  options: BaseTransportOptions & { headers?: HeadersInit; fetchOptions?: object }
): Transport {
  const makeRequest: TransportRequestExecutor = (request) => {
    const controller = new AbortController();

    const timeout = 1000;
    const timeoutId = setTimeout(() => {
      controller.abort();
    }, timeout);

    const requestOptions: RequestInit = {
      body: request.body,
      method: 'POST',
      referrerPolicy: 'origin',
      headers: options.headers,
      ...options.fetchOptions,
      signal: controller.signal,
    };

    return fetch(options.url, requestOptions)
      .then((response) => {
        return {
          statusCode: response.status,
          headers: {
            'x-sentry-rate-limits': response.headers.get('X-Sentry-Rate-Limits'),
            'retry-after': response.headers.get('Retry-After'),
          },
        };
      })
      .finally(() => {
        clearTimeout(timeoutId);
      });
  };

  return Sentry.createTransport(options, makeRequest);
}

Sentry.init({
  dsn: 'https://7741a3516ee507e1119efe04852fa732@sentry.rapidcompact.link/5',

  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1,

  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: false,

  transport: Sentry.makeBrowserOfflineTransport(makeFetchTransport),

  replaysOnErrorSampleRate: 1.0,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,

  // You can remove this option if you're not planning to use the Sentry Session Replay feature:
  integrations: [
    Sentry.replayIntegration({
      // Additional Replay configuration goes in here, for example:
      maskAllText: true,
      blockAllMedia: true,
    }),
    Sentry.browserTracingIntegration(),
  ],
});
